.container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-width: 100%;
  max-height: 0;
  animation: growFormMaxHeight 1.3s ease-in-out;
  animation-fill-mode: forwards;
}

.loadingContainer {
  min-height: 300px;
} 

.container > * {
  overflow: visible;
  padding: 10px;
}

.logo {
  float: right;
}

.container h2 {
  text-align: center;
}

.reactIcon {
  font-size: 1.3em;
  margin-bottom: -0.25em;
}

.headerIcon {
  margin-bottom: -5px;
  margin: auto 10px -5px 0;
  font-size: 25px;
}
  

@media (max-width: 1150px) {
  .container > * > * {
    text-align: left;
  }
}

@keyframes growFormMaxHeight {
  to {
    max-height: 1000px;
  }
}
@keyframes appearFromLeft{
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes appear{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes shake{
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0px);
  }
}


.logo {
  animation-duration: 1300ms;
  animation-name: appear;
}
.hasError {
  animation-duration: 150ms;
  animation-name: shake;
}

.loginAppear {
  animation-duration: 800ms;
  animation-name: appearFromLeft;
}
